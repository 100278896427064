@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

textarea {
  resize: none;
}

h1 {
  font-weight: bold;
  font-size: 2rem;
}

body {
  overflow-x: hidden;
}

.pointer {
  cursor: pointer;
}
