.footer {
  padding: 2rem;
  border-top: 1px solid black;
  ul {
    li {
      padding: 0.5rem 0;
      list-style: none;
    }
  }
  &-icons {
    margin-top: 0.5rem;
    color: black;
    margin-right: 0.5rem;
    cursor: pointer;
  }
}

.Copyright {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
