@mixin image($url) {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url($url);
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel {
  &-img {
    width: 100vw;
    height: 90vh;
    &-one {
      @include image('./../../assets/carousel-one.jpg');
    }
    &-two {
      @include image('./../../assets/carousel-two.jpg');
    }
  }
  &-text {
    width: 100vw;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      font-weight: bold;
      color: white;
      font-size: 3rem;
    }
    h2 {
      color: white;
      font-size: 1.5rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .carousel {
    &-img {
      width: 100vw;
      height: 50vh;
      &-one {
        @include image('./../../assets/carousel-one.jpg');
      }
      &-two {
        @include image('./../../assets/carousel-two.jpg');
      }
    }
    &-text {
      width: 100vw;
      height: 50vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h1 {
        font-weight: bold;
        color: white;
        font-size: 1rem;
      }
      h2 {
        color: white;
        font-size: 0.8rem;
      }
    }
  }
}
