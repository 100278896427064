@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap);
.navbar{width:100vw;height:10vh !important;display:flex;justify-content:space-between;padding:0 2rem;position:fixed;z-index:1;background-color:white}.navbar-logo{width:150px;height:75px}.navbar-links ul{width:100%;height:100%;display:flex;align-items:center;justify-content:space-between}.navbar-links ul li{list-style:none;text-decoration:none;margin:0 2rem;cursor:pointer}.navbar-links-request{border:1px solid black;padding:0.5rem 2rem;border-radius:0.4rem}@media only screen and (max-width: 768px){.navbar{padding:0rem}.navbar-links ul li{margin:0 0.5rem}.navbar-links-request{border:1px solid black;padding:0.5rem 1rem;border-radius:0.4rem}}

.carousel-img{width:100vw;height:90vh}.carousel-img-one{background-image:linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),url(/static/media/carousel-one.cb15121a.jpg);background-repeat:no-repeat;background-size:cover}.carousel-img-two{background-image:linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),url(/static/media/carousel-two.35d10706.jpg);background-repeat:no-repeat;background-size:cover}.carousel-text{width:100vw;height:85vh;display:flex;align-items:center;justify-content:center;flex-direction:column}.carousel-text h1{font-weight:bold;color:white;font-size:3rem}.carousel-text h2{color:white;font-size:1.5rem}@media only screen and (max-width: 768px){.carousel-img{width:100vw;height:50vh}.carousel-img-one{background-image:linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),url(/static/media/carousel-one.cb15121a.jpg);background-repeat:no-repeat;background-size:cover}.carousel-img-two{background-image:linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),url(/static/media/carousel-two.35d10706.jpg);background-repeat:no-repeat;background-size:cover}.carousel-text{width:100vw;height:50vh;display:flex;align-items:center;justify-content:center;flex-direction:column}.carousel-text h1{font-weight:bold;color:white;font-size:1rem}.carousel-text h2{color:white;font-size:0.8rem}}

.about p{text-align:justify;font-size:1.1rem}.about img{width:70%;float:right}.services-head{text-align:center}.services-quote{display:flex;align-items:center;justify-content:center}.about,.services{margin:4rem}@media only screen and (max-width: 768px){.about,.services{margin:1rem}.about p{text-align:justify;font-size:0.8rem}.about img{width:100%;float:none}}.keywords{font-size:10px;color:white;display:none;font-weight:bold}

.footer{padding:2rem;border-top:1px solid black}.footer ul li{padding:0.5rem 0;list-style:none}.footer-icons{margin-top:0.5rem;color:black;margin-right:0.5rem;cursor:pointer}.Copyright{width:100%;display:flex;align-items:center;justify-content:center}

*{padding:0;margin:0;box-sizing:border-box;font-family:'Poppins', sans-serif}textarea{resize:none}h1{font-weight:bold;font-size:2rem}body{overflow-x:hidden}.pointer{cursor:pointer}

