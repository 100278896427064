.about {
  p {
    text-align: justify;
    font-size: 1.1rem;
  }
  img {
    width: 70%;
    float: right;
  }
}
.services {
  &-head {
    text-align: center;
  }
  &-quote {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.about,
.services {
  margin: 4rem;
}

@media only screen and (max-width: 768px) {
  .about,
  .services {
    margin: 1rem;
  }
  .about {
    p {
      text-align: justify;
      font-size: 0.8rem;
    }
    img {
      width: 100%;
      float: none;
    }
  }
}

.keywords {
  font-size: 10px;
  color: white;
  display: none;
  font-weight: bold;
}
