.navbar {
  width: 100vw;
  height: 10vh !important;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  position: fixed;
  z-index: 1;
  background-color: white;
  &-logo {
    width: 150px;
    height: 75px;
  }
  &-links {
    ul {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        list-style: none;
        text-decoration: none;
        margin: 0 2rem;
        cursor: pointer;
      }
    }
    &-request {
      border: 1px solid black;
      padding: 0.5rem 2rem;
      border-radius: 0.4rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    padding: 0rem;
    &-links {
      ul {
        li {
          margin: 0 0.5rem;
        }
      }
      &-request {
        border: 1px solid black;
        padding: 0.5rem 1rem;
        border-radius: 0.4rem;
      }
    }
  }
}
